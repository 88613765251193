
import Vue from "vue";
import store from "@/store";
import API from "@/api/API";
import rules from "@/services/helpers/validation-rules";
import { mask } from "vue-the-mask";
import ClientData from "@/modules/orders/components/ClientData.vue";
import Documents from "@/modules/orders/components/Documents.vue";
import documentsModel from "../../../config/documents-model.json";
import OrderServices from "@/modules/orders/components/OrderServices.vue";
import orderActionsMixin from "@/modules/orders/mixins/orderActionsMixin";
import OrderActions from "@/modules/orders/components/OrderActions.vue";
import Client from "@/modules/orders/mixins/client";

export default Vue.extend({
  name: "AtaCreateOrder",

  components: {
    OrderServices,
    ClientData,
    Documents,
    OrderActions
  },

  props: {
    language: {
      type: String,
      default: "ru",
      required: true
    },
    serviceId: {
      required: true,
      type: Number
    },
    allocations: {
      required: true,
      type: Object
    },
    client: {
      required: true,
      type: Object
    },
    showRegulation: {
      required: true,
      type: Boolean
    }
  },

  directives: { mask },

  mixins: [orderActionsMixin, Client],

  data: () => ({
    rules,
    documentsModel,
    loading: false as boolean,
    isSelectBeneficiary: false as boolean,
    selectedCard: null as null | SelectComponentInterface,
    model: {
      documents: [] as Array<any>,
      supporting_documents: {
        acts_of_proof: [] as Array<any>,
        authority: [] as Array<any>,
        other: [] as Array<any>
      } as any,
      details: { services: [] as Array<any> } as any,
      order: {
        source: "client",
        client_delegate: "aaij",
        document_type: "new",
        client: { type: "" } as any,
        document_valid_from: new Date().toISOString().substr(0, 10),
        power_of_attorney: "beneficiary" as string,
        use_last_power_of_attorney: true as boolean
      } as any
    } as any,
    errorMessages: {} as any,
    selects: {
      cardTypes: [] as Array<SelectComponentInterface>,
      purpose: [] as Array<SelectComponentInterface>,
      packingCategories: [] as Array<SelectComponentInterface>,
      languages: [] as Array<SelectComponentInterface>,
      client_types: [] as Array<SelectComponentInterface>,
      release_types: [] as Array<SelectComponentInterface>,
      areas: ["agro", "other"] as Array<string>
    } as any,
    lang: store.getters["localization/getCurrent"],
    selectedTab: 0,
    totalSum: 0 as number,
    isLoading: false,
    breadcrumb: [
      {
        text: "orders.title",
        disabled: false,
        href: "/orders"
      },
      {
        text: "orders.form.header.create",
        disabled: true,
        href: `/orders/edit`
      }
    ],
    defaultCountry: null as any,
    forceKey: 0 as number,
    powerOfAttorney: null as any,
    isTrustedData: false as boolean,
    regulationData: false as boolean
  }),

  watch: {
    language: {
      immediate: true,
      handler() {
        this.model.order.language = this.language;
      }
    },
    "model.documents": {
      immediate: true,
      deep: true,
      handler() {
        this.$forceUpdate();
      }
    },
    client: {
      immediate: true,
      deep: true,
      handler() {
        this.model.order.client = this.client;
        this.model.order.client_id = this.client?.id;
        this.isSelectBeneficiary = true;
      }
    }
  },

  computed: {
    isOriginalDocument() {
      return this.model.order.document_type === "new";
    },
    areas() {
      return this.selects.areas.map((item: any) => ({
        value: item,
        text: this.$t(`orders.areas.${item}`, this.model.order.language)
      }));
    },
    isClassic() {
      return this.model.order.power_of_attorney === "classic";
    },
    parentLanguage() {
      return this.model.order.language === "ro";
    },
    user() {
      return this.$store.getters["user/info"];
    },
    privacyPolicy() {
      if (!this.showRegulation) {
        return this.isTrustedData;
      }
      return this.isTrustedData && this.regulationData;
    }
  },

  async beforeMount() {
    await this.getBeneficiary();
  },

  async mounted() {
    await this.loadData();
  },

  methods: {
    async getBeneficiary() {
      try {
        this.model.order.client = await this.$API
          .users()
          .getClient(this.user.id);
        this.model.order.client_id = this.model.order.client?.id;
        this.isSelectBeneficiary = true;
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    changeKey() {
      this.forceKey = this.forceKey++;
    },
    getServices(services: Array<any>) {
      this.model.details.services = services;
    },
    getOrderSum(event: any) {
      this.totalSum = event;
    },
    changePowerType(type: string) {
      this.model.order.power_of_attorney = type;
    },
    isJuridicalPerson(): boolean {
      return this.model.order?.client.type === "juridical";
    },
    isDefaultCountry(country_id: number) {
      return this.defaultCountry === country_id;
    },
    async loadData() {
      this.loading = true;
      try {
        const headers = {
          "x-localization": this.language
        };
        const response = await Promise.all([
          API.purposeOfUse().getList(this.serviceId, headers),
          API.orders().getOrderReleaseModes(),
          API.clients().getClientTypes(),
          API.orders().getCardTypes(this.serviceId),
          API.orders().getLanguages(),
          API.branches().getList()
        ]);
        await this.setServerResponse(response);
      } catch (e) {
        await store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    async submit(mode = ""): Promise<void> {
      const form = this.$refs.form as Vue;
      this.isLoading = true;

      try {
        if (form.validate()) {
          this.model.documents = this.model.documents.filter(
            (item: any) => item.file_type !== "confirm_represent_power"
          );
          this.model.documents.push(this.powerOfAttorney);
          const model = { ...this.model };
          model.order.service_type_id = this.serviceId;

          const { branch_id, subdivision_id, handler_id } = this.allocations;
          model.order.branch_id = branch_id;
          model.order.subdivision_id = subdivision_id;
          model.order.handler_id = handler_id;

          const params = { mode };

          await this.$API.orders().create(model, params);

          form.removeGuard();

          await this.$router.push("/orders");
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_create")
          );

          window.scrollTo(0, 0);
        } else {
          this.selectedTab = 0;
          await setTimeout(() => {
            const firstErrorInput = form.inputs.find(
              (item: any) => item.messagesToDisplay.length
            );

            if (firstErrorInput) {
              this.$vuetify.goTo(firstErrorInput.$el, {
                offset: 150
              });
            }
          }, 500);
        }
      } catch (e) {
        form.removeGuard();
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;

          await setTimeout(() => {
            const firstErrorInput = form.inputs.find(
              (item: any) => item.messagesToDisplay.length
            );
            if (firstErrorInput) {
              this.$vuetify.goTo(firstErrorInput.$el, {
                offset: 150
              });
            }
          }, 500);
          setTimeout(() => {
            this.errorMessages = {};
          }, 4000);
        }
        await this.$store.dispatch("alert/showError", e.message || e);
      }
      this.isLoading = false;
    },
    async setServerResponse(response: Array<any>) {
      const [
        purpose,
        releaseModes,
        clientTypes,
        cardTypes,
        languages,
        branches
      ] = response;
      this.selects.languages = languages.items;
      this.selects.purpose = purpose;
      this.selects.release_types = releaseModes.items;
      this.model.order.release_mode = releaseModes.default;
      this.selects.client_types = clientTypes;
      this.selects.cardTypes = cardTypes.items;
      this.model.order.document_type = cardTypes.default;
      this.selects.branches = branches;
    },
    async loadSpecialists(): Promise<void> {
      try {
        this.selects.specialists = await this.$API.users().getList({
          filter: {
            service_type: this.serviceId,
            branch: this.model.order.branch_id
          }
        });

        if (this.selects.specialists.length === 1) {
          const [manager] = this.selects.specialists;
          this.model.details.employee_id = manager.value;
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async search(value: string, key: string): Promise<any> {
      try {
        return await (this.$API as any)[key]().liveSearch(value);
      } catch (e) {
        await this.$store.dispatch("alert/showError");
      }
    },
    async searchClient(value: string, key: string): Promise<any> {
      try {
        return {
          items: await (this.$API as any)[key]().liveSearchClient(value)
        };
      } catch (e) {
        await this.$store.dispatch("alert/showError");
      }
    },
    async setClient(client: any): Promise<void> {
      this.model.order.client_id = client.id;
      this.model.order.client = client;
      this.isSelectBeneficiary = true;
    },
    clearClient(): void {
      this.model.order.client_id = null;
      delete this.model.order.client;
      this.isSelectBeneficiary = false;
    },
    changeData(items: Array<any>) {
      this.model.details.countries = items;
    },
    setParentCard(card: any) {
      if (card?.value) {
        this.model.order.parent_document_id = card.value;
      }
    },
    updateVat(index: number): void {
      this.model.details.services[index].sum_no_vat = Number(
        this.model.details.services[index].sum_no_vat
      );
      this.model.details.services[index].sum_with_vat = this.addVat(
        Number(this.model.details.services[index].sum_no_vat)
      );
      this.model.details.services[index].remained_sum =
        Number(this.model.details.services[index].sum_with_vat) -
        Number(this.model.details.services[index].paid_sum);
      this.getTotalSum();
    },
    addVat(sum: number): number {
      return sum + (sum * 20) / 100;
    },
    removeService(service: any) {
      if (Array.isArray(service)) {
        for (const item of service) {
          const serviceIndex = this.model.details.services.findIndex(
            (el: any) => el.service_id === item
          );
          if (serviceIndex !== -1) {
            this.model.details.services.splice(serviceIndex, 1);
          }
        }
      } else {
        const serviceIndex = this.model.details.services.findIndex(
          (item: any) => item.service_id === service
        );
        if (serviceIndex !== -1) {
          this.model.details.services.splice(serviceIndex, 1);
        }
      }
      this.getTotalSum();
    },
    setService(service: any): void {
      if (
        !this.model.details?.services?.some(
          (item: any) => item.service_id === service.service_id
        )
      ) {
        this.model.details.services.push(service);
      } else {
        const serviceIndex = this.model.details.services.findIndex(
          (item: any) => item.service_id === service.service_id
        );
        this.model.details.services[serviceIndex] = {
          ...this.model.details.services[serviceIndex],
          ...service
        };
      }
      this.getTotalSum();
    },
    getTotalSum() {
      this.totalSum = this.model.details.services.reduce(
        (total: number, { sum_with_vat }: { sum_with_vat: number }) => {
          if (sum_with_vat) {
            return total + Number(sum_with_vat);
          }
          return total;
        },
        0
      );
    },
    setPurposeDescription() {
      const index = this.selects.purpose.findIndex(
        (item: any) => item.value === this.model.details.purpose_id
      );
      if (index !== -1) {
        this.model.details.purpose_description = this.selects.purpose[
          index
        ].description;
      }
    },
    loadDocuments(val: any) {
      this.model.documents = val;
    }
  }
});
